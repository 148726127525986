<template>
  <div class="wrap sign-page">
    <div class="modal-dim on">
      <!--'on 추가'-->
      <div class="set-window-wrap">
        <h1 class="lx-logo"></h1>
        <h2 class="title-txt"></h2>
        <div class="set-window small sign">
          <div class="set-window-top">
            <div class="set-window-sub">
              <div class="sub"><h2></h2></div>
            </div>
          </div>
          <div class="set-window-con" style="border: 1px solid #b7cdbf;">
            <div class="sect" style="background:#fafcfb">
              <div class="form column">
                <p class="form-sub" style="color: #2c3033">이메일*</p>
                <label class="label">
                  <input type="email" placeholder="email" v-model="form.userEmail" @keyup.enter="fnSignin" />
                </label>
              </div>
              <div class="form column">
                <p class="form-sub" style="color: #2c3033">비밀번호*</p>
                <label class="label">
                  <input type="password" placeholder="password" v-model="form.userPw" @keyup.enter="fnSignin" />
                </label>
              </div>
            </div>
            <div class="btn-wrap" style="background:#fafcfb; padding-top: 26px;">
              <button class="btn color" style="width: 100%;" @click="fnSignin">로그인</button>
              <div class="sign-wrap">
                <button class="btn gray" style="width: 100%; color:rgba(58, 64, 68, 0.8);" @click="fnSignUp">회원가입</button>
              </div>
              <br />
              <p class="valid on" v-if="msg.userErr">{{ msg.userErr }}</p>
            </div>
          </div>
        </div>
        <ul class="cust-wrap">
          <li class="cust cust-sync"></li>
          <li class="cust cust-keti"></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, reactive, toRefs, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
  name: 'sign-index',
  setup() {
    const { proxy } = getCurrentInstance()
    const { dispatch } = useStore()
    const router = useRouter()

    const state = reactive({
      form: {
        userEmail: '',
        userPw: ''
      },
      msg: {
        userErr: ''
      }
    })

    onMounted(async () => {
      await dispatch('local/clearData')
    })

    const fnSignin = async () => {
      //로그인 API 타기
      for (let i of document.getElementsByClassName('valid')) {
        i.style.display = ''
      }

      if (proxy.$Util.isEmpty(state.form.userEmail)) {
        state.msg.userErr = '아이디를 입력해 주세요.'
        return false
      }

      if (proxy.$Util.isEmpty(state.form.userPw)) {
        state.msg.userErr = '비밀번호를 입력해 주세요.'
        return false
      }

      if (!proxy.$Util.isEmailValid(state.form.userEmail) || !proxy.$Util.isPasswordValid(state.form.userPw)) {
        state.msg.userErr = '아이디 또는 비밀번호가 유효하지 않습니다.'
        return false
      }

      let userInfo = null
      try {
        userInfo = await proxy.$store.dispatch('user/signIn', state.form)
      } catch (err) {
        state.msg.userErr = '아이디 또는 비밀번호를 잘못 입력했습니다. 입력하신 내용을 다시 확인해주세요.'
      }
      if (userInfo) {
        const user = JSON.parse(userInfo.userInfo)
        if (user.u_t) await router.replace({ name: 'MainIndex' })
      }
    }

    const fnSignUp = async () => {
      await router.push({ name: 'SignUpIndex' })
    }

    return {
      ...toRefs(state),
      fnSignin,
      fnSignUp
    }
  }
}
</script>

<style scoped></style>
